<template>
  <section class="h-screen challenges-bg" id="challenges">
      <div class="flex flex-col justify-center items-center mx-2 pt-14 lg:pt-24">
        <div v-motion-pop-visible>
          <img src="../assets/challenges-title.png" alt="chalenges">
        </div>
        <div class="text-white text-center text-xl md:text-2xl uppercase tracking-[0.3rem] px-4 md:px-24 lg:px-60" style="font-family: Russo One, sans-serif;">
            PLAY UNDER CODE: <span class="text-[#FF9D0A] text-3xl md:text-4xl"><a href="https://roobet.com/?ref=shibbbb" target="_blank">SHIBBBb</a></span> AND SNAG THESE EXCITING CHALLENGES REWARDS ONLY ON ROOBET!
        </div>
      </div>
      <!-- <div class="flex flex-wrap justify-center items-center mt-12 gap-2 md:gap-2">
          <a href="https://roobet.com/game/hacksaw:1067" target="_blank">
              <img v-motion-pop-visible src="../assets/Challenges1.png" class="w-[280px] ml-5 lg:ml-0 lg:w-full" alt="challenge1">
          </a>
          <a href="https://roobet.com/game/pragmatic:vs20sugarrushx" target="_blank">
              <img v-motion-pop-visible src="../assets/Challenges2.png" class="w-[280px] ml-5 lg:ml-0 lg:w-full" alt="challenge2">
          </a>
          <a href="https://roobet.com/game/nolimit:SkateOrDie" target="_blank">
              <img v-motion-pop-visible src="../assets/Challenges3.png" class="w-[280px] ml-5 lg:ml-0 lg:w-full" alt="challenge3">
          </a>
          <a href="https://roobet.com/game/hub88:oct_kawaiiprincess" target="_blank">
              <img v-motion-pop-visible src="../assets/Challenges4.png" class="w-[280px] ml-5 lg:ml-0 lg:w-full" alt="challenge3">
          </a>
      </div> -->
        <div class="text-white text-center text-xl md:text-4xl uppercase tracking-[0.3rem] px-4 md:px-24 lg:px-60 mt-14" style="font-family: Russo One, sans-serif;">
            Coming Soon..
        </div>
      <br><br>
  </section>
</template>

<script>
export default {
  data() {
    return {
      challenges: [],
    };
  },
};
</script>
<style scoped>
.challenges-bg {
  background-image: url('../assets/challenges-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
