<template>
    <section class="h-auto leaderboard-bg">
    <div class="py-14 lg:py-24 px-8">
      <div class="flex flex-row justify-center items-center">
        <img v-motion-pop-visible src="../assets/prev-lead-title.png" class="" alt="prev-leaderboard">
      </div>
      <div v-motion-pop-visible class="flex items-center justify-center mt-6">
          <label
            class="relative flex items-center rounded-full w-[320px] sm:w-[400px] h-[70px] lg:w-[500px] lg:h-[70px] p-1 border border-[rgba(255,255,255,0.2)] bg-gradient-to-bl from-[rgba(255,255,255,0.1)] to-[rgba(221,221,221,0.2)] backdrop-blur-[50px]"
          >
            <!-- Sliding Background -->
            <div
              class="absolute top-1 bottom-1 left-1 h-[calc(100%-8px)] rounded-full transition-all duration-300 ease-in-out"
              :class="{
                'translate-x-[0%]': selectedPrevLeaderboard === 'roobet',
                'translate-x-[110.3%]': selectedPrevLeaderboard === 'packdraw',
                'translate-x-[224.6%]': selectedPrevLeaderboard === 'rain.gg',
                'bg-gradient-to-r': true,
                'from-[#D93105]': selectedPrevLeaderboard == 'roobet',
                'to-[#8A007C]': selectedPrevLeaderboard == 'roobet',
                'from-[#007BBE]': selectedPrevLeaderboard == 'packdraw',
                'to-[#A12687]': selectedPrevLeaderboard == 'packdraw',
                'from-[#d92f05]': selectedPrevLeaderboard == 'rain.gg',
                'to-[#8a007c]': selectedPrevLeaderboard == 'rain.gg',
                'backdrop-blur-[50px]': true,
              }"
              :style="{
                width:
                selectedPrevLeaderboard === 'roobet'
                    ? '30.3%'
                    : selectedPrevLeaderboard === 'packdraw'
                    ? '30.3%'
                    : '30.3%',
              }"
            ></div>

            <div
              class="z-20 flex items-center justify-center w-1/3 h-full transition-all rounded-full cursor-pointer text-white font-bold"
              :class="{
                'bg-transparent': selectedPrevLeaderboard !== 'roobet',
              }"
              @click="toggleLeaderboard('roobet')"
            >
              Roobet
            </div>

            <div
              class="z-20 flex items-center justify-center w-1/3 h-full transition-all rounded-full cursor-pointer text-white font-bold"
              :class="{
                'bg-transparent': selectedPrevLeaderboard !== 'packdraw',
              }"
              @click="toggleLeaderboard('packdraw')"
            >
              Packdraw
            </div>

            <div
              class="z-20 flex items-center justify-center w-1/3 h-full transition-all rounded-full cursor-pointer text-white font-bold"
              :class="{
                'bg-transparent': selectedPrevLeaderboard !== 'rain.gg',
              }"
              @click="toggleLeaderboard('rain.gg')"
            >
              Rain.GG
            </div>
          </label>
        </div>
      <div v-if="prevloading == true" class="text-center text-white text-2xl md:text-4xl mt-10 font-bold" style="font-family: Russo One, sans-serif;">Loading Previous Leaderboard...</div>
      <div v-if="prevloading == false"  class="hidden md:block">
      <div class="flex justify-center mt-8 mb:mb-24" style="font-family: Russo One, sans-serif;">
        <table class="w-full lg:w-auto border-separate border-spacing-y-6">
          <thead v-motion-slide-visible-bottom class="rounded-2xl"
          :class="{
            'bg-gradient-to-r': true,
                'from-[#D93105]': selectedPrevLeaderboard == 'roobet',
                'to-[#8A007C]': selectedPrevLeaderboard == 'roobet',
                'from-[#007BBE]': selectedPrevLeaderboard == 'packdraw',
                'to-[#A12687]': selectedPrevLeaderboard == 'packdraw',
                'from-[#d92f05]': selectedPrevLeaderboard == 'rain.gg',
                'to-[#8a007c]': selectedPrevLeaderboard == 'rain.gg',
            }">
            <tr> 
              <th
                class="px-1 md:px-[45px] lg:pr-24 lg:pl-8 py-2 md:py-4 text-white text-[18px] md:text-xl font-thin text-left rounded-l-full">
                Ranks
              </th>
              <th
                class="px-1 md:px-[45px] lg:px-24 py-2 md:py-4 text-white text-[18px] md:text-xl font-thin text-center">
                User
              </th>
              <th
                class="px-1 md:px-[45px] lg:px-24 py-2 md:py-4 text-white text-[18px] md:text-xl font-thin text-center">
                Amount
              </th>
              <th
                class="px-1 md:px-[45px] lg:pl-24 lg:pr-8 py-2 md:py-4 text-white text-[18px] md:text-xl font-thin text-right rounded-r-full">
                Prize
              </th>
            </tr>
          </thead>
          <div class="mt-4"></div>
          <tbody>
            <tr v-motion-slide-visible-bottom v-for="(item, index) in displayedPrevLead" :key="index">
              <td
                class="px-1 md:px-[45px] lg:px-10 py-2 md:py-4 whitespace-nowrap text-[18px] font-semibold text-left">
                <div class="flex flex-row items-center gap-2">
                  <div class="text-white">{{ getOrdinalSuffix(index + 1) }}</div>
                  <img src="../assets/logo.png" class="w-[40px]" alt="logo">
                </div>
              </td>
              <td
                class="px-1 md:px-[45px] lg:px-24 py-2 md:py-5 whitespace-nowrap text-[18px] text-white font-thin text-center">
                {{ maskString(item.username) }}
              </td>
              <td
                class="px-1 md:px-[45px] lg:px-24 py-2 md:py-5 whitespace-nowrap text-[18px] text-white font-thin text-center">
                ${{ getWager(item) }}
              </td>
              <td
                class="px-1 md:px-[45px] lg:px-8 py-2 md:py-5 whitespace-nowrap text-[18px] text-white font-thin text-right">
                ${{ getRewardValue(index) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
     </div>
      <div v-if="prevloading == false"  class="block md:hidden">
          <div class="flex justify-center mt-8 mb:mb-24 text-xl" style="font-family: Russo One, sans-serif;">
            <table class="w-full lg:w-auto">
              <thead v-motion-slide-visible-bottom>
                <tr>
                  <th
                    class=" md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-left">
                    Ranks
                  </th>
                  <th
                    class=" md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-center">
                    User
                  </th>
                  <th
                    class=" md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-center">
                    Amount
                  </th>
                  <th
                    class=" md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-right">
                    Prize
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-motion-slide-visible-bottom v-for="(item, index) in displayedPrevLead" :key="index">
                  <td
                    class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[14px] text-white font-normal text-left">
                    {{ getOrdinalSuffix(index + 1) }}
                  </td>
                  <td
                    class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[14px] text-white font-normal text-center">
                    {{ maskString(item.username) }}
                  </td>
                  <td
                    class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[14px] text-white font-normal text-center">
                    ${{  getWager(item) }}
                  </td>
                  <td
                    class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[14px] text-white font-normal text-right">
                    ${{ getRewardValue(index) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            displayedPrevLead: [],
            roobetPrevLeaderboard: [],
            packdrawPrevLeaderboard: [],
            rainGGPrevLeaderboard: [],
            prevloading: false,

            selectedPrevLeaderboard: 'roobet',
        }
    },
    methods: {
        async initPrevLeaderboard(){
            const baseUrl = process.env.VUE_APP_BASE_API;
            this.prevloading = true
            await axios.get(`${baseUrl}/api/roobet/leaderboard?previous=true?`, {
            headers: {
                'x-api-key': process.env.VUE_APP_X_API_KEY,
            },
            }).then(res=> {
                this.roobetPrevLeaderboard = res.data.leaderboard;
            });

            await axios.get(`${baseUrl}/api/packdraw/leaderboard?previous=true`,{
            headers: {
                'x-api-key': process.env.VUE_APP_X_API_KEY,
            },
            }).then(res => {
                this.packdrawPrevLeaderboard = res.data.leaderboard;
            });

            await axios.get(`${baseUrl}/api/leaderboard/raingg?previous=true`,{
            headers: {
                'x-api-key': process.env.VUE_APP_X_API_KEY,
            },
            }).then(res => {
                this.rainGGPrevLeaderboard = res.data.leaderboard;
            });

            if(this.prevloading){
              this.changePrevLead()
              this.prevloading = false
            }
        },
        toggleLeaderboard(leaderboardType) {
          this.selectedPrevLeaderboard = leaderboardType;
          this.changePrevLead();
        },
        changePrevLead(){
            if(this.selectedPrevLeaderboard == 'roobet'){
                const prevLeadeboard = this.roobetPrevLeaderboard

                this.displayedPrevLead = prevLeadeboard.slice(0, 10).map((item) => {
                  return item;
                });
            }
            else if(this.selectedPrevLeaderboard == 'packdraw'){
                const prevLeadeboard = this.packdrawPrevLeaderboard

                this.displayedPrevLead = prevLeadeboard.slice(0, 9).map((item) => {
                  return item;
                });
            }
            else if(this.selectedPrevLeaderboard == 'rain.gg'){
                const prevLeadeboard = this.rainGGPrevLeaderboard

                this.displayedPrevLead = prevLeadeboard.slice(0, 8).map((item) => {
                  return item;
                });
            }
        },
        getWager(item){
          if(this.selectedPrevLeaderboard == 'roobet'){
            return item.weightedWagered.toFixed(2)
          }
          else if(this.selectedPrevLeaderboard == 'packdraw'){
            return item.wagerAmount.toFixed(2)
          }
          else if(this.selectedPrevLeaderboard == 'rain.gg'){
            return item.wagered.toFixed(2)
          }
        },

        getRewardValue(rank){
          if(this.selectedPrevLeaderboard == 'roobet'){
            return this.getRoobetRewardValue(rank)
          }
          else if(this.selectedPrevLeaderboard == 'packdraw'){
            return this.getPackDrawRewardValue(rank)
          }
          else if(this.selectedPrevLeaderboard == 'rain.gg'){
            return this.getRainGGRewardValue(rank)
          }
        },
        maskString(str) {
          if (!str || str.length <= 4) return str;

          const start = str.slice(0, 2);
          const end = str.slice(-2);
          const middleAsterisks = '*'.repeat(str.length - 4);

          return `${start}${middleAsterisks}${end}`;
        },
        getOrdinalSuffix(n) {
            const s = ['th', 'st', 'nd', 'rd'],
                v = n % 100;
            return n + (s[(v - 20) % 10] || s[v] || s[0]);
        },
        getRoobetRewardValue(rank) {
          const prizes = JSON.parse(`${process.env.VUE_APP_ROOBET_PREVIOUS_LEADERBOARD_REWARDS}`)
          if (rank >= prizes.length) {
            return prizes[prizes.length-1];
          }
          return prizes[rank];
        },
        getPackDrawRewardValue(rank) {
          const prizes = JSON.parse(`${process.env.VUE_APP_PACKDRAW_PREVIOUS_LEADERBOARD_REWARDS}`)
          if (rank >= prizes.length) {
            return prizes[prizes.length-1];
          }
          return prizes[rank];
        },
        getRainGGRewardValue(rank){
          const prizes = JSON.parse(process.env.VUE_APP_RAIN_PREVIOUS_LEADERBOARD_REWARDS)
          if (rank >= prizes.length) {
            return prizes[prizes.length-1]
          }
          return prizes[rank];
        },
    },
    mounted(){
        this.initPrevLeaderboard()
    }
}
</script>

<style scoped>
.leaderboard-bg {
  background-image: url('../assets/leaderboard-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.toggle-switch {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 393px;
  height: 56px;
  background: linear-gradient(to right, #647f8e, #7e5175);
  border-radius: 28px;
  padding: 0 8px;
  cursor: pointer;
}

.toggle-switch input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-switch .slider {
  position: absolute;
  left: 4px;
  width: 192px;
  height: 48px;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 24px;
  transition: transform 0.3s ease;
}

.toggle-switch input:checked + .slider {
  transform: translateX(192px);
}

.toggle-switch span {
  color: white;
  font-size: 18px;
  font-weight: bold;
  z-index: 1;
  padding: 0 8px;
}

.toggle-switch .left-label {
  position: absolute;
  left: 35px;
  font-weight: 50;
}

.toggle-switch .right-label {
  position: absolute;
  right: 23px;
  font-weight: 50;
}
</style>