<template>
  <!-- Leaderboard Section -->
  <section class="h-auto leaderboard-bg">
    <div class="flex justify-center px-8 md:px-8 lg:pt-36">
      <div
        class="flex flex-col justify-center items-center mt-8 mb-14 lg:my-16 p-8 rounded-3xl" id="leaderboard">
        <div v-show="selectedLeaderboard == false" v-motion-pop-visible class="flex justify-center text-center text-xl text-white px-2 lg:mt-24 shadow-xl uppercase font-base tracking-wider" style="font-family: Roboto, serif;">roobet leaderboard ends every 2 weeks.</div>
        <!-- <div v-show="selectedLeaderboard == false" v-motion-pop-visible class="flex justify-center text-center text-xl text-white px-2 lg:mt-16 md:-mb-14 shadow-xl uppercase font-thin tracking-wider" style="font-family: Roboto, serif;">20% lossback for the first week on new signups</div> -->
        <!-- <div v-show="selectedLeaderboard == false" v-motion-pop-visible class="flex justify-center text-center text-xl text-white px-2 shadow-xl uppercase font-thin tracking-wider" style="font-family: Roboto, serif;">Free 5$ if you sign up. 20$ deposit match up to 20$ for first time depositors</div> -->
        <!-- <div v-show="selectedLeaderboard == false" v-motion-pop-visible class="flex justify-center text-center text-xl text-white px-2 shadow-xl uppercase font-thin tracking-wider" style="font-family: Roboto, serif;">First time depositors will be matched up to $20 only</div> -->
        <div v-show="selectedLeaderboard == true" v-motion-pop-visible class="flex justify-center text-center text-xl text-white px-2 lg:mt-24 shadow-xl uppercase font-base tracking-wider" style="font-family: Roboto, serif;">packdraw leaderboard ends every 2 weeks. 5% bonus every deposit</div>
        <div v-show="selectedLeaderboard == true" v-motion-pop-visible class="flex justify-center text-center text-xl text-white px-2 shadow-xl uppercase font-thin tracking-wider" style="font-family: Roboto, serif;">Code shib must be entered every 24 hours. First time depositors of $15, I'll tip. Contact thru discord</div>
        <!-- <div v-show="selectedLeaderboard == true" v-motion-pop-visible class="flex justify-center text-center text-xl text-white px-2 shadow-xl uppercase font-thin tracking-wider" style="font-family: Roboto, serif;">Highest percentage item pull every 2 weeks will win $200</div> -->
        <div v-show="selectedLeaderboard == 'roobet'" class="flex flex-row justify-center items-center">
          <a href="https://roobet.com/?ref=shibbbb" target="_blank"><img v-motion-slide-visible-left src="../assets/roobet-logo.png" class="hidden md:block" alt="roobet-logo"></a>
          <img v-motion-pop-visible src="../assets/leaderboard.png" class="px-5" alt="leaderboard">
          <a href="https://roobet.com/?ref=shibbbb" target="_blank"><img v-motion-slide-visible-right src="../assets/logo.png" class="hidden md:block" alt="logo"></a>
          <!-- <div class="text-center text-white font-light mt-4" style="font-family: Russo One, sans-serif;">{{ timer }}</div> -->
        </div>
        <div v-show="selectedLeaderboard == 'packdraw'" class="flex flex-row justify-center items-center lg:ml-28">
          <a href="https://packdraw.com/?ref=Shib" target="_blank"><img v-motion-slide-visible-left src="../assets/packdraw-logo.png" class="hidden md:block" alt="packdraw-logo"></a>
          <img v-motion-pop-visible src="../assets/leaderboard2.png" class="px-5" alt="leaderboard2">
          <a href="https://packdraw.com/?ref=Shib" target="_blank"><img v-motion-slide-visible-right src="../assets/logo.png" class="hidden md:block" alt="logo"></a>
          <!-- <div class="text-center text-white font-light mt-4" style="font-family: Russo One, sans-serif;">{{ timer }}</div> -->
        </div>
        <div v-show="selectedLeaderboard == 'rain.gg'" class="flex flex-row justify-center items-center">
          <a href="https://roobet.com/?ref=shibbbb" target="_blank"><img v-motion-slide-visible-left src="../assets/raingg.avif" class="hidden md:block w-[300px]" alt="roobet-logo"></a>
          <img v-motion-pop-visible src="../assets/leaderboard.png" class="px-5" alt="leaderboard">
          <a href="https://roobet.com/?ref=shibbbb" target="_blank"><img v-motion-slide-visible-right src="../assets/logo.png" class="hidden md:block" alt="logo"></a>
          <!-- <div class="text-center text-white font-light mt-4" style="font-family: Russo One, sans-serif;">{{ timer }}</div> -->
        </div>
        <div v-motion-slide-visible-bottom class="text-center text-white font-thin rounded-2xl px-3 py-2 lg:-mt-14" style="font-family: Russo One, sans-serif;"
        :class="{
            'bg-gradient-to-r': true,
            'from-[#D93105]': selectedLeaderboard == 'roobet',
            'to-[#8A007C]': selectedLeaderboard == 'roobet',
            'from-[#007BBE]': selectedLeaderboard == 'packdraw',
            'to-[#A12687]': selectedLeaderboard == 'packdraw',
            'from-[#d92f05]': selectedLeaderboard == 'rain.gg',
            'to-[#8a007c]': selectedLeaderboard == 'rain.gg',
          }" >
          {{ getTimer() }} {{ loading == true ? "Calculating time": '' }}
        </div>
        <!-- <div class="hidden md:block">
          <label class="toggle-switch mt-8" style="font-family: Zen Dots, sans-serif;">
            <input type="checkbox" @change="changeLeaderboard()" v-model="selectedLeaderboard"/>
            <div class="slider border border-white" :class="selectedLeaderboard == true ? 'bg-gradient-to-r from-[#007BBE] to-[#A12687]' : 'bg-gradient-to-r from-[#D93105] to-[#8A007C]'"></div>
            <span class="left-label">ROOBET</span>
            <span class="right-label">PACKDRAW</span>
            <span class="right-label">PACKDRAW</span>
          </label>
        </div> -->
        <!-- <div class="block">
          <ul class="flex flex-wrap gap-3 text-sm font-medium text-center text-gray-500 mt-4" style="font-family: Zen Dots, sans-serif;">
              <li @click="selectedLeaderboard = 'roobet'; changeLeaderboard()" :class="selectedLeaderboard == 'roobet' ? 'bg-gradient-to-r from-[#D93105] to-[#8A007C]' : ''" class="rounded-2xl border border-white cursor-pointer px-4">
                  <div class="inline-block px-4 py-3 text-white">Roobet</div>
              </li>
              <li @click="selectedLeaderboard = 'packdraw'; changeLeaderboard()" :class="selectedLeaderboard == 'packdraw' ? 'bg-gradient-to-r from-[#007BBE] to-[#A12687]' : ''" class="rounded-2xl border border-white cursor-pointer px-3">
                  <div class="inline-block px-4 py-3 text-white">Packdraw</div>
              </li>
              <li @click="selectedLeaderboard = 'rain.gg'; changeLeaderboard()" :class="selectedLeaderboard == 'rain.gg' ? 'bg-gradient-to-r from-[#dec274] to-[#A12687]' : ''" class="rounded-2xl border border-white cursor-pointer px-5">
                  <div class="inline-block px-4 py-3 text-white">Rain.GG</div>
              </li>
          </ul>
        </div> -->

        <div v-motion-pop-visible class="flex items-center justify-center mt-6">
          <label
            class="relative flex items-center rounded-full w-[320px] sm:w-[400px] h-[70px] lg:w-[500px] lg:h-[70px] p-1 border border-[rgba(255,255,255,0.2)] bg-gradient-to-bl from-[rgba(255,255,255,0.1)] to-[rgba(221,221,221,0.2)] backdrop-blur-[50px]"
          >
            <!-- Sliding Background -->
            <div
              class="absolute top-1 bottom-1 left-1 h-[calc(100%-8px)] rounded-full transition-all duration-300 ease-in-out"
              :class="{
                'translate-x-[0%]': selectedLeaderboard === 'roobet',
                'translate-x-[110.3%]': selectedLeaderboard === 'packdraw',
                'translate-x-[224.6%]': selectedLeaderboard === 'rain.gg',
                'bg-gradient-to-r': true,
                'from-[#D93105]': selectedLeaderboard == 'roobet',
                'to-[#8A007C]': selectedLeaderboard == 'roobet',
                'from-[#007BBE]': selectedLeaderboard == 'packdraw',
                'to-[#A12687]': selectedLeaderboard == 'packdraw',
                'from-[#d92f05]': selectedLeaderboard == 'rain.gg',
                'to-[#8a007c]': selectedLeaderboard == 'rain.gg',
                'backdrop-blur-[50px]': true,
              }"
              :style="{
                width:
                  selectedLeaderboard === 'roobet'
                    ? '30.3%'
                    : selectedLeaderboard === 'packdraw'
                    ? '30.3%'
                    : '30.3%',
              }"
            ></div>

            <!-- Stake Logo Button -->
            <div
              class="z-20 flex items-center justify-center w-1/3 h-full transition-all rounded-full cursor-pointer text-white font-bold"
              :class="{
                'bg-transparent': selectedLeaderboard !== 'roobet',
              }"
              @click="toggleLeaderboard('roobet')"
            >
              Roobet
            </div>

            <!-- EmpireDrop Logo Button -->
            <div
              class="z-20 flex items-center justify-center w-1/3 h-full transition-all rounded-full cursor-pointer text-white font-bold"
              :class="{
                'bg-transparent': selectedLeaderboard !== 'packdraw',
              }"
              @click="toggleLeaderboard('packdraw')"
            >
              Packdraw
            </div>

            <!-- Raingg Logo Button -->
            <div
              class="z-20 flex items-center justify-center w-1/3 h-full transition-all rounded-full cursor-pointer text-white font-bold"
              :class="{
                'bg-transparent': selectedLeaderboard !== 'rain.gg',
              }"
              @click="toggleLeaderboard('rain.gg')"
            >
              Rain.GG
            </div>
          </label>
        </div>

        <div v-if="loading == true" class="text-center text-white text-2xl md:text-4xl mt-10 font-bold" style="font-family: Russo One, sans-serif;">Loading Leaderboard...</div>

        <!-- Temporary text for packdraw updating soon -->
        <!-- <div v-if="selectedLeaderboard" class="text-center text-white text-2xl md:text-4xl mt-10 font-bold" style="font-family: Russo One, sans-serif;">Updating soon...</div>
        <div v-show="selectedLeaderboard == true" v-motion-pop-visible class="flex justify-center text-center text-xl text-white px-2 lg:mt-5 shadow-xl uppercase font-base tracking-wider" style="font-family: Roboto, serif;">all wagers from today untill the leaderboard is updated will be included</div> -->

        <div v-if="loading == false"  class="hidden md:block">
          <div class="flex justify-center mt-8 mb:mb-24" style="font-family: Russo One, sans-serif;">
            <table class="w-full lg:w-auto border-separate border-spacing-y-6">
              <thead v-motion-slide-visible-bottom class="rounded-2xl" 
                  :class="{
                      'bg-gradient-to-r': true,
                      'from-[#D93105]': selectedLeaderboard == 'roobet',
                      'to-[#8A007C]': selectedLeaderboard == 'roobet',
                      'from-[#007BBE]': selectedLeaderboard == 'packdraw',
                      'to-[#A12687]': selectedLeaderboard == 'packdraw',
                      'from-[#d92f05]': selectedLeaderboard == 'rain.gg',
                      'to-[#8a007c]': selectedLeaderboard == 'rain.gg',
                  }">
                <tr> 
                  <th
                    class="px-1 md:px-[45px] lg:pr-24 lg:pl-8 py-2 md:py-4 text-white text-[18px] md:text-xl font-thin text-left rounded-l-full">
                    Ranks
                  </th>
                  <th
                    class="px-1 md:px-[45px] lg:px-24 py-2 md:py-4 text-white text-[18px] md:text-xl font-thin text-center">
                    User
                  </th>
                  <th
                    class="px-1 md:px-[45px] lg:px-24 py-2 md:py-4 text-white text-[18px] md:text-xl font-thin text-center">
                    Amount
                  </th>
                  <th
                    class="px-1 md:px-[45px] lg:pl-24 lg:pr-8 py-2 md:py-4 text-white text-[18px] md:text-xl font-thin text-right rounded-r-full">
                    Prize
                  </th>
                </tr>
              </thead>
              <div class="mt-4"></div>
              <tbody>
                <tr v-motion-slide-visible-bottom v-for="(item, index) in top3" :key="index" 
                :class="{
                      'bg-gradient-to-r': true,
                      'from-[#D93105]': selectedLeaderboard == 'roobet',
                      'to-[#8A007C]': selectedLeaderboard == 'roobet',
                      'from-[#007BBE]': selectedLeaderboard == 'packdraw',
                      'to-[#A12687]': selectedLeaderboard == 'packdraw',
                      'from-[#d92f05]': selectedLeaderboard == 'rain.gg',
                      'to-[#8a007c]': selectedLeaderboard == 'rain.gg',
                  }">
                  <td
                    class="px-1 md:px-[45px] lg:px-10 py-2 md:py-4 whitespace-nowrap text-[18px] font-semibold text-left rounded-l-full">
                    <div class="flex flex-row items-center gap-2">
                      <div class="text-white">{{ getOrdinalSuffix(index + 1) }}</div>
                      <img src="../assets/logo.png" class="w-[40px]" alt="logo">
                    </div>
                  </td>
                  <td
                    class="px-1 md:px-[45px] lg:px-24 py-2 md:py-4 whitespace-nowrap text-[18px] text-white font-thin text-center">
                    {{ maskString(item.username) }}
                  </td>
                  <td
                    class="px-1 md:px-[45px] lg:px-24 py-2 md:py-4 whitespace-nowrap text-[18px] text-white font-thin text-center">
                    ${{ getWager(item) }}
                  </td>
                  <td
                    class="px-1 md:px-[45px] lg:px-8 py-2 md:py-4 whitespace-nowrap text-[18px] text-white font-thin text-right rounded-r-full">
                    ${{ getRewardValue(index) }}
                  </td>
                </tr>
              </tbody>
              <div class="mt-8"></div>
              <tbody>
                <tr v-motion-slide-visible-bottom v-for="(item, index) in topRest" :key="index" :class="selectedLeaderboard == true ? 'bg-[#5D4A7A]' : 'bg-[#762402]'">
                  <td
                    class="px-1 md:px-[45px] lg:px-10 py-2 md:py-4 whitespace-nowrap text-[18px] font-semibold text-left">
                    <div class="flex flex-row items-center gap-2">
                      <div class="text-white">{{ getOrdinalSuffix(index + 4) }}</div>
                      <img src="../assets/logo.png" class="w-[40px]" alt="logo">
                    </div>
                  </td>
                  <td
                    class="px-1 md:px-[45px] lg:px-24 py-2 md:py-5 whitespace-nowrap text-[18px] text-white font-thin text-center">
                    {{ maskString(item.username) }}
                  </td>
                  <td
                    class="px-1 md:px-[45px] lg:px-24 py-2 md:py-5 whitespace-nowrap text-[18px] text-white font-thin text-center">
                    ${{ getWager(item) }}
                  </td>
                  <td
                    class="px-1 md:px-[45px] lg:px-8 py-2 md:py-5 whitespace-nowrap text-[18px] text-white font-thin text-right">
                    ${{ getRewardValue(index + 3) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="loading == false"  class="block md:hidden">
          <div class="flex justify-center mt-8 mb:mb-24 text-xl" style="font-family: Russo One, sans-serif;">
            <table class="w-full lg:w-auto">
              <thead v-motion-slide-visible-bottom>
                <tr>
                  <th
                    class=" md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-left">
                    Ranks
                  </th>
                  <th
                    class=" md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-center">
                    User
                  </th>
                  <th
                    class=" md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-center">
                    Amount
                  </th>
                  <th
                    class=" md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-right">
                    Prize
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-motion-slide-visible-bottom v-for="(item, index) in topForMobile" :key="index">
                  <td
                    class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[14px] text-white font-normal text-left">
                    {{ getOrdinalSuffix(index + 1) }}
                  </td>
                  <td
                    class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[14px] text-white font-normal text-center">
                    {{ maskString(item.username) }}
                  </td>
                  <td
                    class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[14px] text-white font-normal text-center">
                    ${{ getWager(item)}}
                  </td>
                  <td
                    class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[14px] text-white font-normal text-right">
                    ${{ getRewardValue(index) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="loading == false && selectedLeaderboard == false" v-motion-pop-visible class="flex justify-center text-white mt-8 lg:mt-14 uppercase font-semibold tracking-wider px-2 md:px-4 lg:px-48 2xl:px-96 2xl:ml-4" style="font-family: Roboto, serif;">
          Your wagers on Roobet will count towards the leaderboard at the following weights based on the games you are playing. This helps prevent leaderboard abuse:

          <br><br>*Games with an RTP under 96% will contribute 100% of the amount wagered to the leaderboard.
          <br>*Games with an RTP of 97% will contribute 50% of the amount wagered to the leaderboard.
          <br>*Games with an RTP of 98- 99% and above will contribute 10% of the amount wagered to the leaderboard.
          <br>*Only house games and slots count(Dice Excluded) towards leaderboard.
        </div>
      </div>
    </div>
  </section>
  <PrevLeaderBoardSection ref="PrevLeaderBoardSection"/>
</template>

<script>
import axios from 'axios';
import moment from 'moment-timezone';
import PrevLeaderBoardSection from './PrevLeaderBoardSection.vue';

export default {
  components: {
    PrevLeaderBoardSection
  },
  data() {
    return {
      top3: [],
      topRest: [],
      topForMobile: [],

      roobetTimer: '',
      packdrawTimer: '',
      rainTimer: '',

      rooberLeaderboard: [],
      packdrawLeaderboard: [],
      rainggLeaderboard: [],

      selectedLeaderboard: 'roobet',

      roobetStartDate: null,
      roobetEndDate: null,

      packdrawStartDate: null,
      packdrawEndDate: null,

      roobetNotFormattedEndDate: null,
      packdrawNotFormattedEndDate: null,
      rainggNotFormattedEndDate: null,

      loading: false,
    };
  },
  methods: {
    async init() {
      const baseUrl = process.env.VUE_APP_BASE_API;
      this.loading = true
        await axios.get(`${baseUrl}/api/roobet/leaderboard?`,{
          headers: {
            'x-api-key': process.env.VUE_APP_X_API_KEY,
          },
        }).then(res => {
          this.rooberLeaderboard = res.data.leaderboard;
          this.roobetNotFormattedEndDate = res.data.endDate
        });

        await axios.get(`${baseUrl}/api/packdraw/leaderboard`,{
          headers: {
            'x-api-key': process.env.VUE_APP_X_API_KEY,
          },
        }).then(res => {
          this.packdrawLeaderboard = res.data.leaderboard
          this.packdrawNotFormattedEndDate = res.data.endDate
        });

        await axios.get(`${baseUrl}/api/leaderboard/raingg`,{
          headers: {
            'x-api-key': process.env.VUE_APP_X_API_KEY,
          },
        }).then(res => {
          this.rainggLeaderboard = res.data.leaderboard
          this.rainggNotFormattedEndDate = res.data.endDate
        });

        if(this.loading == true){
          this.changeLeaderboard()
          this.startRoobetTimer();
          this.startPackdrawTimer()
          this.startRainGGTimer()
          this.loading = false
        }
    },
    changeLeaderboard(){
      if(this.selectedLeaderboard == 'roobet'){
        const leaderboard = this.rooberLeaderboard
        this.top3 = leaderboard.slice(0, 3).map((item) => {
          return item;
        });

        this.topRest = leaderboard.slice(3, 12).map((item) => {
          return item;
        });

        this.topForMobile = leaderboard.slice(0, 12).map((item) => {
          return item;
        });
      }
      
      else if(this.selectedLeaderboard == 'packdraw'){
        const leaderboard = this.packdrawLeaderboard
        this.top3 = leaderboard.slice(0, 3).map((item) => {
          return item;
        });

        this.topRest = leaderboard.slice(3, 9).map((item) => {
          return item;
        });

        this.topForMobile = leaderboard.slice(0, 9).map((item) => {
          return item;
        });
      }
      else if(this.selectedLeaderboard == 'rain.gg'){
        const leaderboard = this.rainggLeaderboard
        this.top3 = leaderboard.slice(0, 3).map((item) => {
          return item;
        });

        this.topRest = leaderboard.slice(3, 8).map((item) => {
          return item;
        });

        this.topForMobile = leaderboard.slice(0, 8).map((item) => {
          return item;
        });
      }
    },

    toggleLeaderboard(leaderboardType) {
      this.selectedLeaderboard = leaderboardType;
      this.changeLeaderboard();
    },

    getWager(item){
      if(this.selectedLeaderboard == 'roobet'){
        return item.weightedWagered.toFixed(2)
      }
      else if(this.selectedLeaderboard == 'packdraw'){
        return item.wagerAmount.toFixed(2)
      }
      else if(this.selectedLeaderboard == 'rain.gg'){
        return item.wagered.toFixed(2)
      }
    },

    getRewardValue(rank){
      if(this.selectedLeaderboard == 'roobet'){
        return this.getRoobetRewardValue(rank)
      }
      else if(this.selectedLeaderboard == 'packdraw'){
        return this.getPackDrawRewardValue(rank)
      }
      else if(this.selectedLeaderboard == 'rain.gg'){
        return this.getRainGGRewardValue(rank)
      }
    },

    getTimer(){
      if(this.selectedLeaderboard == 'roobet'){
        return this.roobetTimer
      }
      else if(this.selectedLeaderboard == 'packdraw'){
        return this.packdrawTimer
      }
      else if(this.selectedLeaderboard == 'rain.gg'){
        return this.rainTimer
      }
    },
    
    getOrdinalSuffix(n) {
      const s = ['th', 'st', 'nd', 'rd'],
        v = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    },
    maskString(str) {
      if (!str || str.length <= 4) return str;

      const start = str.slice(0, 2);
      const end = str.slice(-2);
      const middleAsterisks = '*'.repeat(str.length - 4);

      return `${start}${middleAsterisks}${end}`;
    },
    getRoobetRewardValue(rank) {
      const prizes = JSON.parse(process.env.VUE_APP_ROOBET_CURRENT_LEADERBOARD_REWARDS)
      if (rank >= prizes.length) {
        return prizes[prizes.length-1]
      }
      return prizes[rank];
    },
    getPackDrawRewardValue(rank) {
      const prizes = JSON.parse(process.env.VUE_APP_PACKDRAW_CURRENT_LEADERBOARD_REWARDS)
      if (rank >= prizes.length) {
        return prizes[prizes.length-1]
      }
      return prizes[rank];
    },
    getRainGGRewardValue(rank){
      const prizes = JSON.parse(process.env.VUE_APP_RAIN_CURRENT_LEADERBOARD_REWARDS)
      if (rank >= prizes.length) {
        return prizes[prizes.length-1]
      }
      return prizes[rank];
    },

    startRoobetTimer() {
      const getNextTargetDate = () => {
        const now = moment.utc();
        let targetDate = moment.utc(this.roobetNotFormattedEndDate).subtract({ seconds: 1 })
        
        if (now.isAfter(targetDate)) {
          targetDate.add(2, 'week');
        }

        return targetDate;
      };

      let targetDate = getNextTargetDate();
      let roobetTimerInterval;

      const updateTimer = () => {
        const nowEst = moment.utc();
        const distance = targetDate.diff(nowEst);

        if (distance < 0) {
          this.timer = 'Leaderboard ended';
          this.init()
          this.$refs.PrevLeaderBoardSection.init()
          clearInterval(roobetTimerInterval);

          targetDate = getNextTargetDate();
          this.startRoobetTimer();
        } else {
          const duration = moment.duration(distance);
          const days = Math.floor(duration.asDays());
          const hours = duration.hours();
          const minutes = duration.minutes();
          const seconds = duration.seconds();

          this.roobetTimer = `${days}d ${hours}h ${minutes}m ${seconds}s`;
        }
      };
      updateTimer();
      roobetTimerInterval = setInterval(updateTimer, 1000);
  },
  startPackdrawTimer() {
      const getNextTargetDate = () => {
        const now = moment.utc();
        let targetDate = moment.utc(this.packdrawNotFormattedEndDate).subtract({ seconds: 1 })
        
        if (now.isAfter(targetDate)) {
          targetDate.add(1, 'week');
        }

        return targetDate;
      };
       
      let targetDate = getNextTargetDate();
      let packdrawTimerInterval;

      const updateTimer = () => {
        const nowEst = moment.utc();
        const distance = targetDate.diff(nowEst);

        if (distance < 0) {
          this.timer = 'Leaderboard ended';
          this.init()
          this.$refs.PrevLeaderBoardSection.init()
          clearInterval(packdrawTimerInterval);

          targetDate = getNextTargetDate();
          this.startPackdrawTimer();
        } else {
          const duration = moment.duration(distance);
          const days = Math.floor(duration.asDays());
          const hours = duration.hours();
          const minutes = duration.minutes();
          const seconds = duration.seconds();

          this.packdrawTimer = `${days}d ${hours}h ${minutes}m ${seconds}s`;
        }
      };

      updateTimer();
      packdrawTimerInterval = setInterval(updateTimer, 1000);
    },
    startRainGGTimer() {
      const getNextTargetDate = () => {
        const now = moment.utc();
        let targetDate = moment.utc(this.rainggNotFormattedEndDate).subtract({ seconds: 1 })
        
        if (now.isAfter(targetDate)) {
          targetDate.add(1, 'week');
        }

        return targetDate;
      };
       
      let targetDate = getNextTargetDate();
      let packdrawTimerInterval;

      const updateTimer = () => {
        const nowEst = moment.utc();
        const distance = targetDate.diff(nowEst);

        if (distance < 0) {
          this.timer = 'Leaderboard ended';
          this.init()
          this.$refs.PrevLeaderBoardSection.init()
          clearInterval(packdrawTimerInterval);

          targetDate = getNextTargetDate();
          this.startPackdrawTimer();
        } else {
          const duration = moment.duration(distance);
          const days = Math.floor(duration.asDays());
          const hours = duration.hours();
          const minutes = duration.minutes();
          const seconds = duration.seconds();

          this.rainTimer = `${days}d ${hours}h ${minutes}m ${seconds}s`;
        }
      };

      updateTimer();
      packdrawTimerInterval = setInterval(updateTimer, 1000);
    }
  },
  
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
.leaderboard-bg {
  background-image: url('../assets/leaderboard-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}


.toggle-switch {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 393px;
  height: 56px;
  background: linear-gradient(to right, #647f8e, #7e5175);
  border-radius: 28px;
  padding: 0 8px;
  cursor: pointer;
}

.toggle-switch input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-switch .slider {
  position: absolute;
  left: 4px;
  width: 192px;
  height: 48px;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 24px;
  transition: transform 0.3s ease;
}

.toggle-switch input:checked + .slider {
  transform: translateX(192px);
}

.toggle-switch span {
  color: white;
  font-size: 18px;
  font-weight: bold;
  z-index: 1;
  padding: 0 8px;
}

.toggle-switch .left-label {
  position: absolute;
  left: 35px;
  font-weight: 50;
}

.toggle-switch .right-label {
  position: absolute;
  right: 23px;
  font-weight: 50;
}
</style>
